import { createAction } from 'redux-actions';

export const FETCH_APPOINTMENTS = 'appointments/fetch';
export const FETCH_APPOINTMENTS_SUCCESSFUL = 'appointments/fetchSuccessful';
export const FETCH_APPOINTMENTS_FAILED = 'appointments/fetchFailed';

export const CREATE_APPOINTMENT_REQUEST = 'appointments/createRequest';
export const CREATE_APPOINTMENT_REQUEST_SUCCESSFUL = 'appointments/createRequestSuccessful';
export const CREATE_APPOINTMENT_REQUEST_FAILED = 'appointments/createRequestFailed';

export const ACCEPT_APPOINTMENT = 'appointments/accept';
export const ACCEPT_APPOINTMENT_SUCCESSFUL = 'appointments/acceptSuccessful';
export const ACCEPT_APPOINTMENT_FAILED = 'appointments/acceptFailed';

export const REJECT_APPOINTMENT = 'appointments/cancel';
export const REJECT_APPOINTMENT_SUCCESSFUL = 'appointments/cancelSuccessful';
export const REJECT_APPOINTMENT_FAILED = 'appointments/cancelFailed';

export const REJECT_APPOINTMENT_PUBLIC = 'appointments/cancelPublic';

export const RESCHEDULE_NO_SHOW_APPOINTMENT = 'appointments/rescheduleNoShowAppointment';

export const RESCHEDULE_NO_SHOW_APPOINTMENT_PUBLIC = 'appointments/rescheduleNoShowAppointmentPublic';

export const FETCH_INSURANCES_BY_STATE = 'insurancesByState/fetch';
export const FETCH_INSURANCES_BY_STATE_SUCCESSFUL = 'insurancesByState/fetchSuccessful';
export const FETCH_INSURANCES_BY_STATE_FAILED = 'insurancesByState/fetchFailed';

export const FETCH_ALL_PROVIDER_SERVICES = 'appointments/fetchAllProviderServices';
export const FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL = 'appointments/fetchAllProviderServicesSuccessful';
export const FETCH_ALL_PROVIDER_SERVICES_FAILED = 'appointments/fetchAllProviderServicesFailure';

export const SET_SELECTED_SERVICE = 'appointments/SetSelectedService';
export const SET_SELECTED_SlOT = 'appointments/SetSelectedSlot';
export const SET_SELECTED_SlOT_PROVIDERS = 'appointments/SetSelectedSlotProvider';
export const SET_SELECTED_PROVIDER = 'appointments/SetSelectedProvider';

export const FETCH_MASTER_SCHEDULE = 'appointments/fetchMasterSchedule';
export const FETCH_MASTER_SCHEDULE_SUCCESSFUL = 'appointments/fetchMasterScheduleSuccessful';

export const INSURANCE_DETAILS = 'appointment/insuranceDetails';
export const SELECTED_INSURANCE_DATA = 'appointment/selectedInsuranceData';

export const SAVE_MISSING_INFO_INDICATORS = 'appointment/saveMissingInfoIndicators';

//appointmentCacheActions
export const UPDATE_PROVIDER = 'newScheduling/updateProvider';
export const UPDATE_INSURANCE = 'newScheduling/updateInsurance';
export const UPDATE_INSURANCE_DETAILS = 'newScheduling/updateInsuranceDetails';
export const UPDATE_SERVICE = 'newScheduling/updateService';
export const UPDATE_START_TIME = 'newScheduling/updateStartTime';
export const UPDATE_PROFILE = 'newScheduling/updateProfile';
export const UPDATE_PAYMENT = 'newScheduling/updatePayment';
export const UPDATE_URL_PARAMS = 'newScheduling/updateUrlParams';
export const APPOINTMENT_DETAILS = 'newScheduling/appointmentDetails';
export const APPOINTMENT_CHARGE_AMOUNT = 'newScheduling/appointmentChargeAmount';
export const CLEAR_SCHEDULING_CACHE = 'newScheduling/clearCache';

export const appointmentActionCreators = {
  createAppointment: createAction(CREATE_APPOINTMENT_REQUEST),
  fetchAppointments: createAction(FETCH_APPOINTMENTS),
  acceptAppointment: createAction(ACCEPT_APPOINTMENT),
  rejectAppointment: createAction(REJECT_APPOINTMENT),
  rejectAppointmentPublic: createAction(REJECT_APPOINTMENT_PUBLIC),
  fetchAllProviderServices: createAction(FETCH_ALL_PROVIDER_SERVICES),
  setSelectedService: createAction(SET_SELECTED_SERVICE),
  setSelectedSlot: createAction(SET_SELECTED_SlOT),
  setSelectedSlotProviders: createAction(SET_SELECTED_SlOT_PROVIDERS),
  setSelectedProvider: createAction(SET_SELECTED_PROVIDER),
  fetchMasterSchedule: createAction(FETCH_MASTER_SCHEDULE),
  fetchInsurancesByState: createAction(FETCH_INSURANCES_BY_STATE),
  insuranceDetails: createAction(INSURANCE_DETAILS),
  selectedInsuranceData: createAction(SELECTED_INSURANCE_DATA),
  updateProvider: createAction(UPDATE_PROVIDER),
  updateInsurance: createAction(UPDATE_INSURANCE),
  updateInsuranceDetails: createAction(UPDATE_INSURANCE_DETAILS),
  updateService: createAction(UPDATE_SERVICE),
  updateStartTime: createAction(UPDATE_START_TIME),
  updateProfile: createAction(UPDATE_PROFILE),
  updatePayment: createAction(UPDATE_PAYMENT),
  updateUrlParams: createAction(UPDATE_URL_PARAMS),
  appointmentDetails: createAction(APPOINTMENT_DETAILS),
  appointmentChargeAmount: createAction(APPOINTMENT_CHARGE_AMOUNT),
  clearSchedulingCache: createAction(CLEAR_SCHEDULING_CACHE),
  saveMissingInfoIndicators: createAction(SAVE_MISSING_INFO_INDICATORS),
  rescheduleNoShowAppointment: createAction(RESCHEDULE_NO_SHOW_APPOINTMENT),
  rescheduleNoShowAppointmentPublic: createAction(RESCHEDULE_NO_SHOW_APPOINTMENT_PUBLIC),
};
