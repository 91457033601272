import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Disconnect: FC<IGlyphIcon> = ({ className, color, onClick }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M17.657 14.8284L16.243 13.4144L17.657 12.0004C18.0285 11.6289 18.3231 11.188 18.5241 10.7027C18.7252 10.2173 18.8286 9.69719 18.8286 9.17189C18.8286 8.64659 18.7252 8.12643 18.5241 7.64112C18.3231 7.1558 18.0285 6.71483 17.657 6.34339C17.2856 5.97195 16.8446 5.6773 16.3593 5.47628C15.874 5.27525 15.3538 5.17179 14.8285 5.17179C14.3032 5.17179 13.7831 5.27525 13.2978 5.47628C12.8124 5.6773 12.3715 5.97195 12 6.34339L10.586 7.75739L9.17202 6.34339L10.586 4.92939C11.7143 3.81931 13.2356 3.20005 14.8184 3.20649C16.4012 3.21294 17.9174 3.84457 19.0366 4.9638C20.1558 6.08303 20.7875 7.59919 20.7939 9.18201C20.8004 10.7648 20.1811 12.2861 19.071 13.4144L17.657 14.8284ZM14.828 17.6574L13.414 19.0714C12.8585 19.636 12.1968 20.085 11.4669 20.3926C10.737 20.7002 9.95344 20.8602 9.16141 20.8634C8.36937 20.8666 7.58453 20.713 6.85216 20.4114C6.1198 20.1098 5.45439 19.6661 4.89433 19.1061C4.33427 18.546 3.89064 17.8806 3.58903 17.1483C3.28742 16.4159 3.1338 15.631 3.13702 14.839C3.14025 14.047 3.30026 13.2634 3.60782 12.5335C3.91539 11.8036 4.36442 11.1419 4.92902 10.5864L6.34302 9.17239L7.75702 10.5864L6.34302 12.0004C5.97158 12.3718 5.67694 12.8128 5.47591 13.2981C5.27489 13.7834 5.17142 14.3036 5.17142 14.8289C5.17142 15.3542 5.27489 15.8743 5.47591 16.3597C5.67694 16.845 5.97158 17.2859 6.34302 17.6574C6.71447 18.0288 7.15544 18.3235 7.64075 18.5245C8.12607 18.7255 8.64622 18.829 9.17152 18.829C9.69683 18.829 10.217 18.7255 10.7023 18.5245C11.1876 18.3235 11.6286 18.0288 12 17.6574L13.414 16.2434L14.828 17.6574ZM14.828 7.75739L16.243 9.17239L9.17202 16.2424L7.75702 14.8284L14.828 7.75839V7.75739ZM5.77502 2.29339L7.70702 1.77539L8.74202 5.64039L6.81102 6.15839L5.77502 2.29439V2.29339ZM15.258 18.3614L17.189 17.8434L18.225 21.7074L16.293 22.2254L15.258 18.3614ZM2.29302 5.77539L6.15702 6.81139L5.63902 8.74239L1.77502 7.70739L2.29302 5.77539ZM18.361 15.2584L22.225 16.2934L21.707 18.2254L17.843 17.1894L18.361 15.2584Z"
        fill={color}
      />
    </svg>
  );
};

export default Disconnect;
